<template>
  <div
    class="compare-header"
    :class="{'compare-header_compact': isCompact}"
  >
    <div class="compare-header__container">
      <div
        class="compare-header__item"
        v-for="(index, i) in (isSingle ? [innerIndexes[0]] : innerIndexes)"
        :key="i"
      >
        <div
          class="compare-header__user"
          v-if="results[index]"
          @click="goToParticipantPage(results[index].participantId || results[index].teamId)"
        >
          <div class="compare-header__user-avatar" v-if="!isCompact">
            <Avatar
              :username="results[index].fullName || results[index].teamName"
              :img="results[index].avatarLargeImage && results[index].avatarLargeImage.replace('-x-', '112x112')"
              :size="48"
            >
            </Avatar>
            <CompareButton
              :active="true"
              @toggle="toggleCompare(results[index].participantId || results[index].teamId)"
            ></CompareButton>
          </div>
          <div class="compare-header__user-name text-sm" v-if="!isRelay">
            <template v-if="!isCompact">
              <div>{{ results[index].fullName.split(' ')[0] }}</div>
              <div>{{ results[index].fullName.split(' ')[1] }}</div>
            </template>
            <template v-else>{{ getCompactName(results[index]) }}</template>
          </div>
          <div class="compare-header__user-name text-sm" v-if="isRelay">
            {{ results[index].teamName }}
          </div>
          <div class="text-xs" v-if="results[index].number">№{{ results[index].number }}</div>
        </div>
        <div class="compare-header__controls" v-if="changeIndexEnabled">
          <Link
            class="compare-header__control"
            variant="secondary"
            size="xs"
            icon-left="left"
            tag="button"
            @click="changeIndex(i, -1)"
          ></Link>
          <div class="text-xs">{{ index + 1 }} {{ $t('general.of') }} {{ results.length }}</div>
          <Link
            class="compare-header__control"
            variant="secondary"
            size="xs"
            icon-left="right"
            tag="button"
            @click="changeIndex(i, 1)"
          ></Link>
        </div>
      </div>
      <div
        class="compare-header__item"
        v-if="isSingle"
        @click="$emit('add')"
      >
        <div class="compare-header__add">
          <Icon name="plus-circle" size="xl"></Icon>
          <div
            class="compare-header__add-text"
            v-html="(isRelay ?  $t('compare.add_team') : $t('compare.add_participant')).replace(' ', '<br>')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar/Avatar.vue';
import CompareButton from '@/components/CompareButton/CompareButton.vue';

export default {
  name: 'CompareHeader',
  components: {
    Avatar,
    CompareButton,
  },
  props: {
    results: {
      type: Array,
      default: () => [],
    },
    indexes: {
      type: Array,
      default: () => [],
    },
    isCompact: Boolean,
    isRelay: Boolean,
  },
  computed: {
    isSingle() {
      return this.results.length < 2;
    },
    innerIndexes: {
      get() {
        return this.indexes;
      },
      set(val) {
        this.$emit('change', val);
      },
    },
    changeIndexEnabled() {
      return this.results.length > this.innerIndexes.length;
    },
  },
  methods: {
    changeIndex(i, step) {
      let newIndex = this.innerIndexes[i] + step;
      newIndex = newIndex < 0 ? this.results.length - 1 : newIndex;
      newIndex = newIndex > this.results.length - 1 ? 0 : newIndex;

      if (this.innerIndexes.indexOf(newIndex) !== -1) {
        this.innerIndexes[i] = newIndex;
        this.changeIndex(i, step);
        return;
      }

      this.$set(this.innerIndexes, i, newIndex);
    },
    getCompactName(result) {
      const parts = result.fullName.split(' ');
      return `${parts[0] || ''} ${parts[1] ? `${parts[1][0]}.` : ''}`.trim() || result.Name;
    },
    fixIndexes() {
      if (this.results.length < 2) {
        this.innerIndexes = [0, 1];
        return;
      }
      this.innerIndexes.forEach((index, i) => {
        if (!this.results[index]) {
          this.changeIndex(i, 1);
        }
      });
    },
    toggleCompare(id) {
      this.$emit('compare', id);
      setTimeout(this.fixIndexes);
    },
    goToParticipantPage(participantId) {
      this.$router.push({
        name: this.isRelay ? 'Team' : 'Participant',
        params: { participantId },
      });
    },
  },
  created() {
    this.fixIndexes();
  },
};
</script>

<style lang="scss" scoped>
@import "./CompareHeader";
</style>
