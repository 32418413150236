<template>
  <main class="layout__main">
    <Header
      @clickBackButton="$goBack(backLink)"
      :shiftTitle="false"
      isWide
      :backButtonProps="backButtonProps"
    >
      <template v-slot:center v-if="themeSettings.noEventLinks">
        <a :href="themeUrls.Main">
          <img :src="themeImages.logo" alt="" style="width: var(--header-logo-width)">
        </a>
      </template>
      <template v-slot:title v-if="!themeSettings.noEventLinks">
        <div class="text-ellipsis" v-if="themeSettings.eventCode">
          <b>{{ event.title }}</b>
        </div>
        <Link
          v-else
          v-show="event.title"
          class="rr-link_ellipsis"
          :href="`${themeUrls.Events}/event/${event.code || event.id}`"
          :text="`<span class='color-text'>${event.title}</span>`"
          variant="secondary"
          size="md"
          icon-right="externa-link"
          target="_blank"
        ></Link>
      </template>
      <template v-slot:right>
        <Button
          class="d-none d-md-block"
          variant="secondary"
          icon-left="plus"
          :text="$t('compare.add')"
          @click="openSearch()"
        ></Button>
        <Button
          class="d-md-none"
          shape="square"
          variant="clear"
          icon-size="xl"
          icon-left="plus"
          @click="openSearch()"
        ></Button>
      </template>
      <template v-slot:bottom>
        <div
          v-if="comparingResults.results"
          v-show="compareHeaderFixed"
          style="padding: 12px 8px;"
        >
          <CompareHeader
            :results="comparingResults.results"
            :indexes="comparingIndexes"
            @change="comparingIndexes = $event"
            :isRelay="comparingResults.config.isRelay"
            is-compact
            @add="openSearch({ raceId: comparingRace.id })"
          ></CompareHeader>
        </div>
      </template>
    </Header>
    <div class="rr-header-offset"></div>
    <section class="section_pt section_pb">
      <Loader v-if="isLoading"></Loader>
      <div class="container container_wide" v-else-if="!isEmpty">
        <div class="ml-auto mr-auto" style="max-width: 1312px;">
        <Row class="h1_mb" align="center">
          <Col size="stretch">
            <h1>{{ $t('compare.title') }} <span class="subtitle color-muted" v-show="totalCount">({{ totalCount }})</span></h1>
          </Col>
          <Col size="auto" class="d-none d-md-block">
            <label class="text-md" for="showCheckpoints" style="user-select: none;">
              <b>{{ $t('result.checkpoints') }}</b>
            </label>&nbsp;&nbsp;
            <RrSwitch
              id="showCheckpoints"
              v-model="showCheckpoints"
            ></RrSwitch>
          </Col>
        </Row>
        <div class="container__mobile" v-if="!isLoading">
          <!-- Results : BEGIN -->
          <Row vertical-gutters v-if="!isCompare">
            <Col
              v-for="race in races"
              :key="race.id"
            >
              <div class="block block_wide p-0" v-show="resultsByRaces[race.id]">
                <div class="block__group">
                  <Link
                    :to="{ name: 'Results', params: { eventCode, raceCode: race.code} }"
                  >
                    <h3>{{ race.name }}</h3>
                  </Link>
                </div>
                <div class="block__group pt-0">
                  <component
                    class="compare-results"
                    v-if="!isLoading && resultsByRaces[race.id]"
                    :is="!race.isRelay ? 'IndividualResultsTable' : 'RelayResultsTable'"
                    :raceId="race.id"
                    :config="resultsByRaces[race.id].config"
                    :results="resultsByRaces[race.id].results"
                    :stages="resultsByRaces[race.id].stages || []"
                    :showCheckpoints="showCheckpoints"
                    :isOpenable="false"
                    :compareList="race.followIds"
                    @compare="removeFromCompare($event, race.id)"
                  ></component>
                </div>
              </div>
            </Col>
          </Row>
          <!-- Results : END -->
          <!-- Compare : BEGIN -->
          <div class="block block_wide p-0" v-if="isCompare">
            <div class="block__group">
              <Select
                v-model="comparingRaceCode"
                :options="comparingOptions"
                :icon-left="'running'"
                track-by="code"
                dropdown-auto-width
                wrap-text
                mobile
              ></Select>
            </div>
            <div class="block__group pt-0">
              <Row align="center" class="d-md-none">
                <Col size="stretch">
                  <label class="text-md" for="showCheckpoints2" style="user-select: none;">
                    <b>{{ $t('result.checkpoints') }}</b>
                  </label>
                </Col>
                <Col size="auto">
                  <RrSwitch
                    id="showCheckpoints2"
                    v-model="showCheckpoints"
                  ></RrSwitch>
                </Col>
              </Row>
            </div>
            <div
              class="block__group section-block__group_bordered-top"
              v-if="comparingResults.results"
            >
              <CompareHeader
                :results="comparingResults.results"
                :indexes="comparingIndexes"
                :isRelay="comparingResults.config.isRelay"
                @change="comparingIndexes = $event"
                @compare="removeFromCompare($event, comparingRace.id)"
                @add="openSearch({ raceId: comparingRace.id })"
              ></CompareHeader>
              <div ref="compare-header-bottom"></div>
              <CompareTable
                :config="comparingResults.config"
                :results="comparingResults.results"
                :stages="comparingResults.stages || []"
                :indexes="comparingIndexes"
                :showCheckpoints="showCheckpoints"
              ></CompareTable>
            </div>
          </div>
          <!-- Compare : END -->
        </div>
        </div>
      </div>
      <!-- Empty state : BEGIN -->
      <div class="container" v-if="isEmpty">
        <div class="container__mobile">
          <Row justify="center">
            <Col :size="{lg: '20'}">
              <div class="block block_wide">
                <EmptyState
                  :img="themeImages['add-user']"
                  :title="$t('compare.empty_title')"
                  :subtitle="$t('compare.empty_subtitle')"
                >
                  <div class="section__group">
                    <Button
                      variant="primary"
                      :text="$t('compare.add')"
                      size="lg"
                      icon-left="plus"
                      @click="openSearch()"
                    ></Button>
                  </div>
                </EmptyState>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <!-- Empty state : END -->
    </section>
    <CompareSearch
      ref="followSearch"
      :eventId="event.id"
      :eventCode="eventCode"
      :raceId="searchRaceId"
      :members="members"
      :races="event.races"
      @afterAdd="afterSearch"
    ></CompareSearch>
  </main>
</template>

<script>
import { ResultCalcMethod } from '@/enums';
import { mapActions, mapGetters } from 'vuex';
import resultApi from '@/services/results-api';
import { groupArray } from '@/assets/js/helpers';
import Header from '@/sections/Header/HeaderExternalBack.vue';
import Select from '@rr-component-library/select/src/main';
import RrSwitch from '@/components/Switch/Switch.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';
import CompareHeader from './CompareHeader/CompareHeader.vue';
import CompareTable from './CompareTable/CompareTable.vue';
import CompareSearch from './CompareSearch/CompareSearch.vue';

export default {
  name: 'Compare',
  components: {
    Header,
    Select,
    RrSwitch,
    CompareHeader,
    CompareTable,
    CompareSearch,
    EmptyState,
    IndividualResultsTable: () => import('@/sections/Results/IndividualResultsTable.vue'),
    RelayResultsTable: () => import('@/sections/Results/RelayResultsTable.vue'),
  },
  props: {
    eventCode: String,
  },
  data() {
    return {
      ResultCalcMethod,
      races: [],
      resultsByRaces: {},
      members: [],
      isEmpty: false,
      isLoading: false,
      comparingRaceCode: null,
      comparingIndexes: [0, 1],
      compareHeaderFixed: false,
      searchRaceId: null,
      showCheckpoints: false,
    };
  },
  computed: {
    ...mapGetters('event', ['event', 'raceInfo']),
    ...mapGetters('follow', ['getFollowListByKeys']),
    ...mapGetters('settings', ['themeImages', 'themeUrls', 'themeSettings', 'theme']),
    backLink() {
      return {
        name: 'Results',
        params: {
          eventCode: this.eventCode,
          raceCode: this.comparingRaceCode,
        },
      };
    },
    isCompare() {
      return window.innerWidth < 768;
    },
    compareList() {
      const keys = (this.event.races || []).map((o) => `follow_${this.eventCode}_${o.id}`);
      return this.getFollowListByKeys(keys);
    },
    comparingOptions() {
      return this.races.map((o) => ({
        id: o.id,
        code: o.code,
        name: o.name,
      }));
    },
    comparingRace() {
      return this.comparingRaceCode
        ? this.races.find((o) => o.code === this.comparingRaceCode) || {}
        : {};
    },
    comparingResults() {
      return this.resultsByRaces[this.comparingRace?.id] || {};
    },
    totalCount() {
      return Object.keys(this.resultsByRaces).reduce((count, key) => (
        count + this.resultsByRaces[key].results.length
      ), 0);
    },
    backButtonProps() {
      const propsDict = {
        rzd: {
          shape: 'circle',
          variant: 'secondary',
          'icon-size': 'm',
          'icon-left': 'left',
        },
      };

      return propsDict[this.theme];
    },
  },
  methods: {
    ...mapActions('event', ['getEvent', 'getRaceInfo']),
    ...mapActions('follow', ['toggleFollow']),
    mapResults(results, config) {
      const stagesKey = config.isRelay ? 'stagesResults' : 'stageResults';
      const stageIdKey = config.isRelay ? 'stageId' : 'raceStageId';
      const raceStages = config.stagesInfo || [];
      const { calcMethod } = config;

      function mapStage(raceStage, resultStages) {
        const stage = (resultStages || []).find((o) => o[stageIdKey] === raceStage.id);
        const resultByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualResult
          : stage?.absoluteResult;
        const delayByCalcMethod = calcMethod === ResultCalcMethod.Individual
          ? stage?.individualDelay
          : stage?.absoluteDelay;

        return {
          ...(stage || {}),
          stageId: raceStage.id,
          name: raceStage.name,
          disciplineCode: raceStage.disciplineCode,
          isMissed: !stage,
          resultByCalcMethod: stage ? (resultByCalcMethod || '00:00') : null,
          delayByCalcMethod,
        };
      }

      return results.map((result) => {
        const participants = groupArray(result.participants, 'participantId');

        const resultStages = raceStages.map((raceStage) => {
          const resultStage = mapStage(raceStage, result[stagesKey]);

          if (config.isRelay) {
            resultStage.participant = participants[resultStage.participantId];
          }

          resultStage[stagesKey] = raceStage.stagesInfo
            .map((raceSubstage) => mapStage(raceSubstage, resultStage[stagesKey]));
          return resultStage;
        });

        return {
          ...result,
          resultByCalcMethod: calcMethod === ResultCalcMethod.Individual
            ? result.individualResult
            : result.absoluteResult,
          delayByCalcMethod: calcMethod === ResultCalcMethod.Individual
            ? result.individualDelay
            : result.absoluteDelay,
          [stagesKey]: resultStages,
        };
      });
    },
    loadResults(raceCodes) {
      let id = 0;
      this.setRaces();

      if (!this.races.length) {
        this.isEmpty = true;
        this.isLoading = false;
      }

      this.races.forEach(async (race) => {
        if (raceCodes && raceCodes.indexOf(race.code) === -1) {
          id += 1;
          return;
        }
        await this.getRaceInfo({
          raceId: race.id,
        });

        const config = this.raceInfo(race.id) || {};

        const method = race.isRelay
          ? 'getComparedRelayResults'
          : 'getComparedIndividualResults';

        resultApi[method]({
          raceId: race.id,
          page: {
            skip: 0,
            take: 40,
          },
          ids: race.followIds,
          isStagesOn: true,
        })
          .then((response) => {
            const { data } = response;

            const results = this.mapResults(data.results || [], config);

            this.$set(this.resultsByRaces, race.id, {
              results,
              config,
              stages: config.stagesInfo || [],
            });
          })
          .finally(() => {
            id += 1;
            if (id === this.races.length) {
              this.isEmpty = !this.totalCount;
              this.isLoading = false;
            }
          });
      });
    },
    setRaces() {
      this.races = [];
      this.members = [];

      (this.event.races || []).forEach((race) => {
        const followIds = this.compareList[`follow_${this.eventCode}_${race.id}`];
        if (followIds && followIds.length) {
          this.races.push({
            ...race,
            followIds,
          });
          this.members.push(...followIds);
        }
      });

      if (!this.comparingRaceCode || !this.comparingRace.id) {
        this.comparingRaceCode = this.races[0] ? this.races[0].code : null;
      }
    },
    async loadEvent() {
      await this.getEvent({
        eventIdOrCode: this.eventCode,
      });
      this.comparingRaceCode = this.$route.params.raceCode || null;
    },
    openSearch(data) {
      const { raceId } = data || {};
      this.searchRaceId = raceId;
      this.$refs.followSearch.open();
    },
    afterSearch(raceCode) {
      if (raceCode && raceCode !== this.comparingRaceCode) {
        this.comparingRaceCode = raceCode;
      }
      this.loadResults([raceCode]);
    },
    toggleCompareHeader() {
      if (this.$refs['compare-header-bottom']) {
        this.compareHeaderFixed = window.pageYOffset > this.$refs['compare-header-bottom'].offsetTop - 56;
      }
    },
    removeFromCompare(id, raceId) {
      const toRemove = this.compareList[`follow_${this.eventCode}_${raceId}`].indexOf(id) !== -1;
      if (toRemove) {
        this.resultsByRaces[raceId].results = this.resultsByRaces[raceId].results
          .filter((o) => (o.participantId || o.teamId) !== id);
        this.toggleFollow({
          id,
          eventCode: this.eventCode,
          raceId,
        });
      }
      this.setRaces();
      this.isEmpty = !this.totalCount;
    },
  },
  watch: {
    isCompare: {
      handler(val) {
        if (val) {
          document.addEventListener('scroll', this.toggleCompareHeader);
        } else {
          document.removeEventListener('scroll', this.toggleCompareHeader);
        }
      },
      immediate: true,
    },
    comparingRaceCode(val) {
      const scrollPosition = window.scrollY;
      this.$router.replace({
        params: this.$route.params.raceCode = val,
      })
        .then(() => { window.scrollTo(0, scrollPosition); })
        .catch(() => {});
    },
  },
  async created() {
    this.isLoading = true;
    await this.loadEvent();
    this.loadResults();
  },
};
</script>

<style lang="scss" scoped>
@import "./Compare";
</style>
