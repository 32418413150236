<template>
  <Modal
    class="follow-search-modal"
    ref="modal"
    name="followSearch"
    :title="$t('participant_search.title')"
    :noTransition="isMobile"
    @afterClose="afterModalClose"
  >
    <SearchResults
      ref="searchResults"
      v-model="value"
      :minLength="1"
      :placeholder="$t('participant_search.placeholder')"
      :isOpened="searchOpened"
      @search="searchParticipants"
      @change="cancelSearchParticipants"
      @select="addFollow"
      @close="close"
    >
      <template v-slot:field>
        <Input
          ref="searchInput"
          v-model.trim="value"
          :placeholder="$t('participant_search.placeholder')"
          icon-left="search"
          clearable
          @focus="searchOpened = true"
        ></Input>
      </template>
      <template v-slot:resultLeftPart="{ result }">
        <div class="search-result__title" v-html="getTitle(result)"></div>
        <div class="search-result__subtitle">
          <div class="text-with-icon text-nowrap">
            <Icon
              class="icon color-muted"
              :name="getRaceIcon(result.raceId)"
              size="s"
            ></Icon>&nbsp;&nbsp;<span>{{ result.raceName }}</span>
          </div>
        </div>
      </template>
      <template v-slot:resultRightPart="{ result }">
        <div class="text-with-icon color-success" v-if="result.isFollowed">
          <Icon name="check-bold" size="m"></Icon>
          <span class="d-none d-md-inline-block"><b class="text-nowrap">{{ $t('participant_search.in_comparison') }}</b></span>
        </div>
        <Icon name="plus" size="m" v-else></Icon>
      </template>
      <template v-slot:tipPlaceholder v-if="isMobile">
        <div
          class="text-center"
          v-html="$t('participant_search.title')"
        ></div>
      </template>
      <template v-slot:tipNotFound>
        {{ $t('participant_search.not_found') }}
      </template>
    </SearchResults>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex';
import resultsApi from '@/services/results-api';
import { markQueryInString } from '@/assets/js/helpers';
import Modal from '@/components/Modal/Modal.vue';
import Input from '@rr-component-library/input/src/main';
import SearchResults from '@/components/SearchResults/SearchResults.vue';

export default {
  name: 'FollowSearch',
  components: {
    Modal,
    Input,
    SearchResults,
  },
  props: {
    eventId: String,
    eventCode: String,
    raceId: String,
    members: Array,
    races: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: null,
      searchOpened: false,
      isMobile: window.innerWidth < 768,
    };
  },
  methods: {
    ...mapActions('follow', ['toggleFollow']),
    getTitle(result) {
      const title = result.startNumber
        ? `${result.fullName} (№&nbsp;${result.startNumber})`
        : result.fullName;

      return markQueryInString(title, this.value);
    },
    open() {
      this.isMobile = window.innerWidth < 768;
      this.$refs.modal.open();
      if (this.isMobile) {
        this.searchOpened = true;
      } else {
        setTimeout(() => {
          this.$refs.searchInput.$el.querySelector('input').focus();
        });
      }
    },
    close() {
      if (this.isMobile) {
        this.$refs.modal.close();
      } else {
        this.searchOpened = false;
      }
    },
    getRaceIcon(raceId) {
      const race = this.races.find((o) => o.id === raceId);
      return race ? race.disciplineIcon : null;
    },
    afterModalClose() {
      this.searchOpened = false;
      this.$refs.searchResults.reset();
    },
    cancelSearchParticipants() {
      resultsApi.cancelSearchResults();
    },
    searchParticipants(value) {
      this.isLoading = true;
      const params = {
        eventId: this.eventId,
        searchValue: value,
        raceId: this.raceId,
        page: {
          skip: 0,
          take: this.isMobile ? 5 : 3,
        },
        pageSize: 25,
      };
      resultsApi.searchResults(params)
        .then((response) => {
          const results = (response.data.participants || []).map((o) => {
            const isFollowed = this.members.indexOf(o.participantId) !== -1;
            return {
              ...o,
              classes: { 'search-result_followed': isFollowed },
              isFollowed,
            };
          });
          this.$refs.searchResults.setResults(value, results);
        })
        .catch(() => {
          this.$refs.searchResults.setResults(value, null);
        });
    },
    addFollow(result) {
      if (!result.isFollowed) {
        this.toggleFollow({
          id: result.participantId,
          eventCode: this.eventCode,
          raceId: result.raceId,
        });
        this.$emit('afterAdd', result.raceCode);
      }
      this.$refs.modal.close();
    },
  },
  mounted() {
    const input = this.$refs.searchInput.$el.querySelector('input');
    input.addEventListener('focus', (e) => {
      this.$refs.searchInput.$emit('focus', e);
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./CompareSearch";
</style>
